export default {
  colors: {
    primary: '#2388ed',
    background: '#fff',
    shape: '#F2F2FA',
    title: '#151515',
    text: '#6C6C80'
  },
  sidebar: {
    background: '#ffffff',
    link: '#999',
    heading: '#aaa',
    linkActive: '#13131A',
    itemActive: '#F5F5FA'
  }
};
