import React from 'react';
import { Global, css } from '@emotion/core';
import BaseGlobalStyle from '@rocketseat/gatsby-theme-docs/src/styles/global';
import { useTheme } from 'emotion-theming';
import { lighten } from 'polished';

export default function GlobalStyle() {
  const theme = useTheme();

  return (
    <>
      <BaseGlobalStyle />
      <Global
        styles={css`
          @import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');

          p {
            color: #404040;
            line-height: 26px;
            text-align: justify;
            text-justify: inter-word;
          }

          tt,
          pre[class*='language-'] code,
          pre[class*='language-']::before,
          code.inline-code,
          kbd,
          .gatsby-code-title {
            font-family: Inconsolata, Consolas, Courier New, monospace;
          }

          code.inline-code {
            padding: 0.2rem 0.4rem;
            color: ${theme.colors.primary};
            background: #f1f3f7;
          }

          main a {
            text-decoration: none !important;
            color: ${theme.colors.primary};

            &:hover {
              color: ${lighten('0.2', theme.colors.primary)}!important;
              transition: background-color 0.2s, color 0.2s, padding-left 0.2s;
            }
          }
        `}
      />
    </>
  );
}
